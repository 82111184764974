import type { TestGroupUpdatesEvent } from 'common/domain-events';

export const handleTestGroupUpdates = (params: {
    event: TestGroupUpdatesEvent;
}) => {
    for (const update of params.event.updates) {
        const trackingUrl = `https://www.campusexplorer.com/track/webevent/event.gif?testgroup=${
            update.testName
        }-${update.testGroup.toString()}`;
        const img = new Image();
        img.src = trackingUrl;
        img.width = 1;
        img.height = 1;
        img.style.display = 'none';
        document.body.appendChild(img);
    }
};
